.topics-bar {
  overflow: auto;
  white-space: nowrap;
  justify-self: center;
  align-self: center;
}

.topic {
  display: inline-block;
  padding: 10px;
  margin: 10px;
  border-radius: 40px;
  border: 1px solid #fff;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

.topic-icon {
  margin: 0px 5px 0px 20px;
  font-size: 20px;
  color: #fff;
}

.topic-name {
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.topic-btn:hover {
  background-color: #d1a51f !important;
  color: white !important;
}

.topic-btn:hover span {
  color: white !important;
}

.topic-btn {
  font-weight: bold !important;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
  padding: 7px 15px;
  margin: 10px 0px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.test {
  color: #545794;
}

/* If screen size is less than 600px, no margin horizontal */
